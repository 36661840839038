import axios from 'axios'
import { Toast } from 'antd-mobile'
//使用create方法创建axios实例

let token = window.location.search.split('=')[1]
token = decodeURI(token)
if(token && token.indexOf('Bearer') == -1) {
  token = 'Bearer ' + token
}
const Request = axios.create({
  timeout: 100000, // 请求超时时间
  timeoutErrorMessage: '请求超时',
  method: 'post',
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  }
})
// 添加请求拦截器
Request.interceptors.request.use(async config => {
  Toast.loading('Loading...', 0)
  return config
})
// 添加响应拦截器
Request.interceptors.response.use(async response => {
  Toast.hide()
  let result = { code: 'error' };
  const obj = await response.data;
  if (obj?.code == 'success') {
    result = obj;
  } else {
    Toast.hide()
    if (obj?.errorMsg || obj?.msg) {
      Toast.info(obj?.msg || obj?.errorMsg, 3)
    } else {
      Toast.info('服务异常', 3)
    }
  }
  return result;
})
export default Request