import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import UserData from '../../utils/userInfo.js'
import { List, InputItem, Button, Toast } from 'antd-mobile';
import request from '../../utils/request'
import MyLogin from '../../images/login.png'

const Login = () => {
  let history = useHistory();
  let userInfo = localStorage.getItem('userInfo')
  if(userInfo) {
    userInfo = JSON.parse(userInfo)
  }
  const [name, setName] = useState(()=>userInfo?.name || '')
  const [password, setPassword] = useState(()=>userInfo?.password || '')
  
  const loginUser = () => {
    if(!name) {
      Toast.info('请输入账号！', 1)
      return
    }
    if(!password) {
      Toast.info('请输入密码！', 1)
      return
    }
    request.post(`/api/login.do`, { account: name,password }).then(v=>{
      if(v.code == 'success' && v.data) {
        const info = v.data
        if(info?.roleName == '气站单位' || info?.roleName == '检验单位') {
          UserData.roleName = info?.roleName
          UserData.unitName = info?.unitName
          UserData.id = info?.account
          const userInfo = { name,password }
          localStorage.setItem("userInfo",JSON.stringify(userInfo))
          history.push('/index')
        } else {
          Toast.info('没有权限!', 2)
        }
      } else {
        Toast.info('登录失败!', 2)
      }
    })
  }
  return (
    <div style={{ height: '100vh', width: '100%', backgroundImage: "url(" + MyLogin + ")", backgroundSize: '100% 100%' }}>
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
        <h3 style={{marginTop: '100px'}}>登录</h3>
        <div style={{marginTop: '60px', padding: '0 50px'}}>
          <List>
            <InputItem
              value={name}
              placeholder='请输入用户账号'
              onChange={value=>setName(value)}
            >姓名</InputItem>
            <div style={{height: '1px', width: '100%'}}></div>
            <InputItem
              value={password}
              placeholder='请输入用户密码'
              onChange={value=>setPassword(value)}
              type='password'
            >密码</InputItem>
          </List>
          <Button type="primary" style={{marginTop: '40px'}} onClick={()=>loginUser()}>登录</Button>
        </div>
      </div>
    </div>
  );
}

export default Login;
