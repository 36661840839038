import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Button, NavBar, Icon, Toast, InputItem, List, Picker, DatePicker, Radio, ImagePicker } from 'antd-mobile';
import moment from 'moment'
import request from '../../utils/request'
import path from '../../utils/path'
import './index.css'

const baseUrl = 'http://59.54.90.177:8080/repository'
const CrudArchive = ({ location }) => {
  let history = useHistory();
  const type = location.state?.type
  const data = location.state?.data
  const [values, setValues] = useState({
    unitId: '', makeId: '', type: 1, medium: 1, code: '',
    factoryNumber: '', arrivalDate: moment().format('YYYY-MM-DD'), madeDate: '', nextInspectionDate: '', inspectionDate: '',
    specificationsId: 1, originalWeight: '16.5', waterGage: '3.2', volume: '35.5', workingPressure: '2.1', wallThickness: '2.5', remarks: '自由产权瓶',
    qualityCertificate: 1, checkout: 1, titleErtificate: 1, certificate: 1, address: '', inspectionNumber: '', scrapDate: '',
    codePath: [], madeDatePath: [], factoryNumberPath: [], checkoutPath: [],
  })
  const [gaUnit, setGaUnit] = useState([])
  const [makeUnit, setMakeUnit] = useState([])
  const [specification, setSpecification] = useState([])
  const [error, setError] = useState()
  useEffect(() => {
    request.post(path.getGAGasStation).then(v => {
      if (v.code == 'success' && Array.isArray(v.data)) {
        const newData = v.data.map(v => {
          return { value: v.id, label: v.name }
        })
        setGaUnit(newData)
      }
    })

    request.post(path.getAllMake).then(v => {
      if (v.code == 'success' && Array.isArray(v.data)) {
        const newData = v.data.map(v => {
          return { value: v.id, label: v.name }
        })
        setMakeUnit(newData)
      }
    })

    request.post(path.getGasSpecifications).then(v => {
      if (v.code == 'success' && Array.isArray(v.data)) {
        const newData = v.data.map(v => {
          return { value: v.id, label: v.name }
        })
        setSpecification(newData)
      }
    })
  }, [])
  useEffect(() => {
    if (data && data.id) {
      setValues({ ...values, ...data, checkoutPath: data.checkoutPath ? [{ url: baseUrl + data.checkoutPath }] : [], codePath: data.codePath?[{ url: baseUrl + data.codePath }]:[], madeDatePath: data.madeDatePath?[{ url: baseUrl + data.madeDatePath }]:[], factoryNumberPath: data.factoryNumberPath?[{ url: baseUrl + data.factoryNumberPath }]:[] })
    }
  }, [data])


  const submit = () => {
    let url = 'editGasArchives'
    if (type == 'add') {
      url = 'addGasArchives'
    }
    // if(values.codePath.length < 1 || values.factoryNumberPath.length < 1 || values.madeDatePath.length < 1) {
    //   Toast.info('请上传图片!', 2)
    //   return
    // }
    if (!values.inspectionDate || !values.code || !values.factoryNumber || !values.madeDate) {
      Toast.info('有必填项没有输入,请检查!', 2)
      return
    }
    request.post(path[url], { ...values, checkoutPath: values.checkoutPath[0]?.url.replace('http://59.54.90.177:8080/repository', ''), codePath: values.codePath[0]?.url.replace('http://59.54.90.177:8080/repository', ''), madeDatePath: values.madeDatePath[0]?.url.replace('http://59.54.90.177:8080/repository', ''), factoryNumberPath: values.factoryNumberPath[0]?.url.replace('http://59.54.90.177:8080/repository', '') }).then(v => {
      if (v.code == 'success') {
        Toast.info('保存成功!', 1)
        history.push('/archives')
      }
    })
  }
  return (
    <div style={{ background: '#fff', minHeight: '100vh' }}>
      {/* <div>{error}</div> */}
      <NavBar mode="dark" icon={<Icon type="left" />} onLeftClick={() => history.push(`/archives`)}>
        <div>{type == 'add' ? '添加气瓶档案' : '修改气瓶档案'}</div>
      </NavBar>
      <div style={{ marginTop: '10px', padding: '0 10px' }}>
        <List className='myList'>
          <Picker data={gaUnit} cols={1} value={[Number(values.unitId)]} onChange={value => setValues({ ...values, unitId: value[0] })}>
            <List.Item wrap arrow="horizontal">充装单位</List.Item>
          </Picker>

          <Picker extra="请选择制造单位" data={makeUnit} cols={1} value={[Number(values.makeId)]} onChange={value => setValues({ ...values, makeId: value[0] })}>
            <List.Item wrap arrow="horizontal">制造单位</List.Item>
          </Picker>
          <Picker data={[{ value: 1, label: '液化石油气瓶' }, { value: 2, label: '工业气瓶' }]} cols={1} value={[Number(values.type)]} onChange={value => setValues({ ...values, type: value[0] })}>
            <List.Item wrap arrow="horizontal">气瓶种类</List.Item>
          </Picker>
          <Picker data={[{ value: 1, label: '液化石油气' }, { value: 2, label: '工业气瓶' }]} cols={1} value={[Number(values.medium)]} onChange={value => setValues({ ...values, medium: value[0] })}>
            <List.Item wrap arrow="horizontal">充装介质</List.Item>
          </Picker>
          <InputItem
            value={values.code}
            onChange={value => setValues({ ...values, code: value })}
            placeholder='(必填)请输入'
          >二维码</InputItem>

          <List.Item extra={
            <ImagePicker
              style={{ height: 100, width: 100 }}
              files={values.codePath}
              onChange={files => setValues({ ...values, codePath: files })}
              selectable={values.codePath.length < 1}
              accept="image/jpeg,image/jpg,image/png"
              length={1}
            />
          }>
            二维码图片
          </List.Item>

          <InputItem
            placeholder='(必填)请输入'
            value={values.factoryNumber}
            onChange={value => setValues({ ...values, factoryNumber: value })}
          >出厂编号</InputItem>

          <List.Item extra={
            <ImagePicker
              style={{ height: 100, width: 100 }}
              files={values.factoryNumberPath}
              onChange={files => setValues({ ...values, factoryNumberPath: files })}
              selectable={values.factoryNumberPath.length < 1}
              accept="image/jpeg,image/jpg,image/png"
              length={1}
            />
          }>
            出厂编号图片
          </List.Item>

          <DatePicker
            mode="date"
            value={values.arrivalDate ? moment(values.arrivalDate).toDate() : undefined}
            onChange={date => setValues({ ...values, arrivalDate: moment(date).format('YYYY-MM-DD') })}
          >
            <List.Item arrow="horizontal">进站日期</List.Item>
          </DatePicker>

          <DatePicker
            extra="(必填)请选择"
            mode="date"
            value={values.madeDate ? moment(values.madeDate).toDate() : undefined}
            onChange={date => setValues({ ...values, madeDate: moment(date).format('YYYY-MM-DD') })}
          >
            <List.Item arrow="horizontal">制造年月</List.Item>
          </DatePicker>

          <List.Item extra={
            <ImagePicker
              style={{ height: 100, width: 100 }}
              files={values.madeDatePath}
              onChange={files => setValues({ ...values, madeDatePath: files })}
              selectable={values.madeDatePath.length < 1}
              accept="image/jpeg,image/jpg,image/png"
              length={1}
            />
          }>
            <div>
              <div>制造年月</div>
              <div>单位图片</div>
            </div>
          </List.Item>

          <DatePicker
            extra="(必填)请选择"
            mode="date"
            value={values.inspectionDate ? moment(values.inspectionDate).toDate() : undefined}
            onChange={date => setValues({ ...values, inspectionDate: moment(date).format('YYYY-MM-DD') })}
          >
            <List.Item arrow="horizontal">本次年检日期</List.Item>
          </DatePicker>

          <List.Item extra={
            <ImagePicker
              style={{ height: 100, width: 100 }}
              files={values.checkoutPath}
              onChange={files => setValues({ ...values, checkoutPath: files })}
              selectable={values.checkoutPath.length < 1}
              accept="image/jpeg,image/jpg,image/png"
              length={1}
            />
          }>
            <div>检验图片</div>
          </List.Item>

          {/* <DatePicker
            extra="(必填)请选择"
            mode="date"
            value={values.nextInspectionDate ? moment(values.nextInspectionDate).toDate() : undefined}
            onChange={date => setValues({ ...values, nextInspectionDate: moment(date).format('YYYY-MM-DD') })}
          >
            <List.Item arrow="horizontal">下验年月</List.Item>
          </DatePicker> */}

          {/* <DatePicker
            mode="date"
            value={values.scrapDate ? moment(values.scrapDate).toDate() : undefined}
            onChange={date => setValues({ ...values, scrapDate: moment(date).format('YYYY-MM-DD') })}
            maxDate={new Date(moment().add(100, 'y').year(), 1, 1, 23, 59, 59)}
          >
            <List.Item arrow="horizontal">报废年月</List.Item>
          </DatePicker> */}


          <Picker data={specification} cols={1} value={[Number(values.specificationsId)]} onChange={value => setValues({ ...values, specificationsId: value[0] })}>
            <List.Item wrap arrow="horizontal">气瓶规格</List.Item>
          </Picker>

          <InputItem
            value={values.originalWeight}
            onChange={value => setValues({ ...values, originalWeight: value })}
          >原始重量</InputItem>
          <InputItem
            value={values.waterGage}
            onChange={value => setValues({ ...values, waterGage: value })}
            extra="(MPa)"
          >水试验压力</InputItem>
          <InputItem
            value={values.volume}
            onChange={value => setValues({ ...values, volume: value })}
          >气瓶容积</InputItem>

          <InputItem
            value={values.workingPressure}
            onChange={value => setValues({ ...values, workingPressure: value })}
          >工作压力</InputItem>
          <InputItem
            value={values.wallThickness}
            onChange={value => setValues({ ...values, wallThickness: value })}
          >设计壁厚</InputItem>

          <InputItem
            value={values.remarks}
            onChange={value => setValues({ ...values, remarks: value })}
          >气瓶备注</InputItem>

          <List.Item extra={<>
            <Radio className="my-radio" checked={values.qualityCertificate == 1} onChange={e => setValues({ ...values, qualityCertificate: 1 })}>有</Radio>
            <Radio style={{ marginLeft: '10px' }} className="my-radio" checked={values.qualityCertificate == 0} onChange={e => setValues({ ...values, qualityCertificate: 0 })}>无</Radio>
          </>}>
            质量证书
          </List.Item>

          <List.Item extra={<>
            <Radio className="my-radio" checked={values.checkout == 1} onChange={e => setValues({ ...values, checkout: 1 })}>有</Radio>
            <Radio style={{ marginLeft: '10px' }} className="my-radio" checked={values.checkout == 0} onChange={e => setValues({ ...values, checkout: 0 })}>无</Radio>
          </>}>
            检验证书
          </List.Item>
          <List.Item extra={<>
            <Radio className="my-radio" checked={values.titleErtificate == 1} onChange={e => setValues({ ...values, titleErtificate: 1 })}>有</Radio>
            <Radio style={{ marginLeft: '10px' }} className="my-radio" checked={values.titleErtificate == 0} onChange={e => setValues({ ...values, titleErtificate: 0 })}>无</Radio>
          </>}>
            产权证明
          </List.Item>
          <List.Item extra={<>
            <Radio className="my-radio" checked={values.certificate == 1} onChange={e => setValues({ ...values, certificate: 1 })}>有</Radio>
            <Radio style={{ marginLeft: '10px' }} className="my-radio" checked={values.certificate == 0} onChange={e => setValues({ ...values, certificate: 0 })}>无</Radio>
          </>}>
            合格证明
          </List.Item>

          <InputItem
            value={values.address}
            onChange={value => setValues({ ...values, address: value })}
          >地址</InputItem>

          <InputItem
            placeholder='(在期必填)请输入'
            value={values.inspectionNumber}
            onChange={value => setValues({ ...values, inspectionNumber: value })}
          >检验环编号</InputItem>
          <Button type='primary' style={{ margin: '20px 0', marginBottom: '40px' }} onClick={() => submit()}>确定</Button>
        </List>
      </div>
    </div>
  );
}

export default CrudArchive;
