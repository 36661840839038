import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Button, NavBar, Icon, InputItem, List, Picker, Toast } from 'antd-mobile';
import request from '../../utils/request'
import path from '../../utils/path'
import '../checkQrcode/index.css'

const AfterQrcode = () => {
  let history = useHistory();
  const [leakage, setLeakage] = useState(['不漏'])
  const [bottle, setBottle] = useState(['完好'])
  const [weight, setWeight] = useState(['等于充装量'])
  const [accessory, setAccessory] = useState(['完好'])
  const [label, setLabel] = useState(['完好'])
  const [code, setCode] = useState('')
  useEffect(() => {
  }, [])

  const checkSubmit = () => {
    if(!code) {
      Toast.info('请输入二维码!', 1)
      return
    }
    const params = {
      code, leakage:leakage[0], bottle:bottle[0], weight:weight[0], accessory:accessory[0], label:label[0]
    }
    request.post(path.addAfterFilling, params).then(v => {
      if (v.code == 'success') {
        Toast.success('保存成功！', 1)
        history.push('/index')
      }
    })
  }

  const splitQrcode = value => {
    const arr = value.split('/')
    setCode(arr[arr.length - 1])
  }
  return (
    <div style={{ background: '#fff', minHeight: '100vh' }}>
      <NavBar mode="dark" icon={<Icon type="left" />} onLeftClick={() => history.push(`/index`)}>
        <div>充装后复查</div>
      </NavBar>
      <div style={{paddingBottom: '20px'}}>
        <div style={{background: '#108ee9', marginTop: '-1px', height: '100px', position: 'relative', padding: '0 20px'}}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', background: '#fff', borderRadius: '8px', position: 'absolute', 
                marginTop:'30px', width:'calc(100% - 40px)', paddingBottom: '30px', boxShadow: '0px 0px 10px 0px rgb(192 215 246)'  }}>
            <span style={{marginTop: '30px', marginBottom: '10px'}}>二维码扫码</span>
            <InputItem style={{ border: '1px solid #ccc', height: '30px', borderRadius: '4px' }} clear type='number' autoFocus="autofocus" placeholder='光标处...' onChange={value=>splitQrcode(value)} value={code} />
          </div>
        </div>
        <List className='checkList' style={{marginTop: '120px'}}>
          <Picker data={[{value: '不漏', label: '不漏'},{value: '漏', label: '漏'}]} cols={1} value={leakage} onChange={val=>setLeakage(val)}>
            <List.Item wrap arrow="horizontal">泄漏检查 <span style={{fontSize: '13px', color: '#ccc'}}>选填(结果以填入的优先)</span></List.Item>
          </Picker>
          <Picker data={[{value: '完好', label: '完好'}]} cols={1} value={bottle} onChange={val=>setBottle(val)}>
            <List.Item wrap arrow="horizontal">瓶况检查 <span style={{fontSize: '13px', color: '#ccc'}}>选填(结果以填入的优先)</span></List.Item>
          </Picker>
          <Picker data={[{value: '等于充装量', label: '等于充装量'}]} cols={1} value={weight} onChange={val=>setWeight(val)}>
            <List.Item wrap arrow="horizontal">实际充装 <span style={{fontSize: '13px', color: '#ccc'}}>选填(结果以填入的优先)</span></List.Item>
          </Picker>
          <Picker data={[{value: '完好', label: '完好'},{value: '不全', label: '不全'}]} cols={1} value={accessory} onChange={val=>setAccessory(val)}>
            <List.Item wrap arrow="horizontal">附件检查 <span style={{fontSize: '13px', color: '#ccc'}}>选填(结果以填入的优先)</span></List.Item>
          </Picker>
          <Picker data={[{value: '完好', label: '完好'},{value: '不全', label: '不全'}]} cols={1} value={label} onChange={val=>setLabel(val)}>
            <List.Item wrap arrow="horizontal">标签检查 <span style={{fontSize: '13px', color: '#ccc'}}>选填(结果以填入的优先)</span></List.Item>
          </Picker>
        </List>
        <div style={{display:'flex', justifyContent: 'center', marginBottom: '20px'}}>
          <Button type='primary' style={{marginTop: '30px', width: '90%'}} onClick={()=>checkSubmit()}>检查</Button>
        </div>
      </div>
    </div>
  );
}

export default AfterQrcode;
