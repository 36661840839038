import { useHistory } from "react-router-dom";
import { List, Button } from 'antd-mobile';
import MyTabBar from '../../components/MyTabBar/index'
import '../baseInfo/baseInfo.css'

const Item = List.Item;

const Examine = ({ location }) => {
  let history = useHistory();
  const data = location.state?.data
  const urlCode = location.state?.urlCode

  return (
    <>
      <div style={{
        fontSize: '5vw', display: 'flex', flexDirection: 'column', lineHeight: '24px', alignItems: 'center',
        height: '60px', justifyContent: 'center', background: '#00479d', color: '#fff'
      }}>
        <div>{data.unitName}</div>
      </div>
      <div style={{width: '90vw', margin: '0 auto'}}>
        <List renderHeader={() => '检验信息'} className="baseInfo-list">
          <Item extra={data.nextInspectionDate}>下次检验年月: </Item>
          <Item extra={data.scrapDate}>设计使用年限: </Item>
          <Item extra={data.madeDate}>生产年月: </Item>
        </List>
        <Button type="primary" style={{ marginTop: '10px',background:'linear-gradient(90deg,#3066ae,#444a9e)' }} onClick={() => history.push(`/gas/${urlCode}`)}>返回</Button>
      </div>
      <MyTabBar />
    </>
  );
}

export default Examine;
