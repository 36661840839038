import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { gasUserData } from '../../../utils/userInfo.js'
import { List, InputItem, Button, Toast } from 'antd-mobile';
import request from '../../../utils/request'
import CircleImg from '../../../images/gas/circle.png'

const Login = () => {
  let history = useHistory();
  let gasUser = localStorage.getItem('gasUser')
  if (gasUser) {
    gasUser = JSON.parse(gasUser)
  }
  const [unitCode, setUnitCode] = useState(() => gasUser?.unitCode || '')
  const [account, setAccount] = useState(() => gasUser?.account || '')
  const [password, setPassword] = useState(() => gasUser?.password || '')

  const loginUser = () => {
    if (!unitCode) {
      Toast.info('请输入企业编码!', 1)
      return
    }
    if (!account) {
      Toast.info('请输入员工编码!', 1)
      return
    }
    if (!password) {
      Toast.info('请输入密码！', 1)
      return
    }
    request.post(`/gasManage/login.do`, { account, unitCode, password }).then(v => {
      if (v.code == 'success' && v.data) {
        const info = v.data
        gasUserData.id = info?.id
        gasUserData.supplyStationId = info?.supplyStationId
        const userInfo = { unitCode, account, password }
        localStorage.setItem("gasUser", JSON.stringify(userInfo))
        if (window.ReactNativeWebView) {
          console.log('info?.id ', info?.id);
          const obj = {
            employeesId: info?.id,
            unitId: info?.unitId,
          }
          window.ReactNativeWebView.postMessage(JSON.stringify(obj));
        }
        history.push('/gasDelivery')
      } else {
        Toast.info('登录失败!', 2)
      }
    })
  }
  return (
    <div style={{ height: '100vh', width: '100%', background: '#fff' }}>
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', height: '100%' }}>
        <div style={{ background: '#000', borderRadius: '50%', padding: 10, marginTop: 40 }}>
          <img src={CircleImg} />
        </div>
        <div style={{ marginTop: '60px', padding: '0 50px' }}>
          <List>
            <InputItem
              value={unitCode}
              placeholder='请输入企业编码'
              onChange={value => setUnitCode(value)}
            >企业编码</InputItem>
            <div style={{ height: '10px', width: '100%' }}></div>
            <InputItem
              value={account}
              placeholder='请输入员工编码'
              onChange={value => setAccount(value)}
            >员工编码</InputItem>
            <div style={{ height: '10px', width: '100%' }}></div>
            <InputItem
              value={password}
              placeholder='请输入用户密码'
              onChange={value => setPassword(value)}
              type='password'
            >密码</InputItem>
          </List>
          <Button style={{ marginTop: '40px', background: '#515151', color: '#fff' }} onClick={() => loginUser()}>登录</Button>
        </div>
      </div>
    </div>
  );
}

export default Login;
