import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Button, NavBar, Icon } from 'antd-mobile';
import request from '../../utils/request'
import path from '../../utils/path'

const RefillInfo = ({ location }) => {
  let history = useHistory();
  const data = location.state?.data
  const urlCode = location.state?.urlCode
  const [info, setInfo] = useState([])
  useEffect(() => {
    request.post(path.getFilling,{code:urlCode}).then(v => {
      if(v.code=='success' && Array.isArray(v.data)) {
        setInfo(v.data)
      }
    })
  }, [])
  return (
    <div style={{background: '#fff'}}>
      <NavBar mode="dark" icon={<Icon type="left" />} onLeftClick={() => history.push(`/gas/${urlCode}`)}>
        <div>{data.unitName}</div>
      </NavBar>
      <div style={{width: '90vw', margin: '0 auto'}}>
        {
          info.length>0?
          <div style={{width: '90vw', margin: '0 auto', marginTop: '15px'}}>
            <h3 style={{textAlign: 'center'}}>只显示最新50条记录</h3>
            <div style={{display:'flex', justifyContent: 'space-between', borderBottom: '1px solid #ccc', paddingBottom: '5px', marginBottom: '5px'}}>
              <div>充装气站</div>
              <div>充装时间</div>
              <div>充装后重量</div>
            </div>
            
              {info.map((item, index)=>{
                return(
                  <div key={index} style={{display:'flex', justifyContent: 'space-between', marginBottom: '8px'}}>
                    <div>{item.unitName}</div>
                    <div>{item.beginTime}</div>
                    <div>{item.after}</div>
                  </div>
                )
              })}
          </div>
          :
          <div style={{width: '90vw', margin: '0 auto'}}>
            <p style={{fontSize:'5vw',lineHeight:'16vw',textAlign:'center',marginTop:'3vw'}}>该气瓶暂无充装信息!</p>
            <Button type="primary" style={{ marginTop: '10px', background:'linear-gradient(90deg,#3066ae,#444a9e)' }} onClick={() => history.push(`/gas/${urlCode}`)}>返回</Button>
          </div>
        }
      </div>
    </div>
  );
}

export default RefillInfo;
