import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { List, Button } from 'antd-mobile';
import MyTabBar from '../../components/MyTabBar/index'
import './baseInfo.css'

const Item = List.Item;

const BaseInfo = ({ location }) => {
  let history = useHistory();
  const data = location.state?.data
  const urlCode = location.state?.urlCode

  return (
    <>
      <div style={{
        fontSize: '5vw', display: 'flex', flexDirection: 'column', lineHeight: '24px', alignItems: 'center',
        height: '60px', justifyContent: 'center', background: '#00479d', color: '#fff'
      }}>
        <div>{data.unitName}</div>
      </div>
      <div style={{width: '90vw', margin: '0 auto'}}>
        <List renderHeader={() => '基本信息'} className="baseInfo-list">
          <Item extra={data.unitName}>充装单位：</Item>
          <Item extra={data.factoryNumber}>出厂编号: </Item>
          <Item extra={data.address}>气瓶地址: </Item>
          <Item extra={data.status}>气瓶状态: </Item>
          <Item extra={data.type == 1 ? '液化石油气' : '工业气瓶'}>气瓶种类: </Item>
          <Item extra={data.specifications}>气瓶规格: </Item>
          <Item extra={data.volume}>气瓶容积: </Item>
          <Item extra={data.phone}>气站电话: </Item>
          <Item extra={data.telephone}>座机电话: </Item>
        </List>
        <Button type="primary" style={{ marginTop: '10px', background:'linear-gradient(90deg,#3066ae,#444a9e)' }} onClick={() => history.push(`/gas/${urlCode}`)}>返回</Button>
      </div>
      <MyTabBar />
    </>
  );
}

export default BaseInfo;
