import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import index from './pages/index'
import BaseInfo from './pages/baseInfo'
import CreatOrder from './pages/creatOrder'
import CheckInfo from './pages/checkInfo'
import Examine from './pages/examine'
import RefillInfo from './pages/refillInfo'

import CheckQrcode from './pages/checkQrcode'
import AfterQrcode from './pages/afterQrcode'
import AppIndex from './pages/appIndex'
import Login from './pages/login'
import Archives from './pages/archives'
import CheckArchive from './pages/checkArchive'
import CrudArchive from './pages/crudArchive'

import gasDeliveryIndex from './pages/gasDelivery'
import gasDeliveryLogin from './pages/gasDelivery/login'
import gasDeliveryOrder from './pages/gasDelivery/order'
import gasDeliveryOrderInfo from './pages/gasDelivery/order/orderInfo'

import './App.css';


const App = () => {
  return (
    <Switch>
      {/* <Route exact path='/' component={index} /> */}
      <Route exact path='/gas' component={index} />
      <Route exact path='/gas/:id' component={index} />
      <Route exact path='/BaseInfo' component={BaseInfo} />
      <Route exact path='/CheckInfo' component={CheckInfo} />
      <Route exact path='/Examine' component={Examine} />
      <Route exact path='/RefillInfo' component={RefillInfo} />
      <Route exact path='/CreatOrder' component={CreatOrder} />

      <Route exact path='/index' component={AppIndex} />
      <Route exact path='/login' component={Login} />
      <Route exact path='/checkQrcode' component={CheckQrcode} />
      <Route exact path='/afterQrcode' component={AfterQrcode} />
      <Route exact path='/archives' component={Archives} />
      <Route exact path='/checkArchive' component={CheckArchive} />
      <Route exact path='/crudArchive' component={CrudArchive} />


      <Route exact path='/gasDelivery' component={gasDeliveryIndex} />
      <Route exact path='/gasDelivery/login' component={gasDeliveryLogin} />
      <Route exact path='/gasDelivery/order' component={gasDeliveryOrder} />
      <Route exact path='/gasDelivery/order/info' component={gasDeliveryOrderInfo} />
    </Switch>
  );
}

export default App;
