import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Button, NavBar, Icon, Toast, InputItem, List, Picker, DatePicker, TextareaItem } from 'antd-mobile';
import moment from 'moment'
import request from '../../utils/request'
import path from '../../utils/path'
import './index.css'

const CheckArchive = ({ location }) => {
  let history = useHistory();
  const [name, setName] = useState([])
  const [searchValue, setSearchValue] = useState({})
  const [values, setValues] = useState({
    isSurface: 1, surface: '良好', baseSpacing: '2.3', airtightness: '2.6', inspectionNumber: '20103',
    bottleValve: '良好', isBottleValve: 1, waterGage: '25', wallThickness: '3.6', onTest: 1, isValveSeat: 1, valveSeat: '良好',
    volume: '4.6', inspectionDate: moment().format('YYYY-MM-DD'), inspector:'', weldLine: '良好',isWeldLine: 1, deformation: 3,weight:13,remark: '',
  })
  const [result, setResult] = useState([1])
  
  const serachData = () => {
    if(name && name != '') {
      request.post(path.getGasArchives, { code: name }).then(v => {
        if (v.code == 'success') {
          setSearchValue(v.data)
        }
      })
    }
  }
  const submit = () => {
    if(searchValue.id && searchValue.unitId) {
      request.post(path.addTestResults, { ...values, unitId: searchValue.unitId, archivesId:searchValue.id }).then(v => {
        if (v.code == 'success') {
          Toast.info('保存成功!', 1)
          history.push('/index')
        }
      })
    } else {
      Toast.info('没找到档案!', 1)
    }
  }

  const splitQrcode = value => {
    const arr = value.split('/')
    setName(arr[arr.length - 1])
  }

  return (
    <div style={{ background: '#fff', minHeight: '100vh' }}>
      <NavBar mode="dark" icon={<Icon type="left" />} onLeftClick={() => history.push(`/index`)}>
        <div>检验管理</div>
      </NavBar>
      <div>
        <InputItem
          value={name}
          placeholder='请输入二维码'
          onChange={value => splitQrcode(value)}
          autoFocus="autofocus"
          clear
          type='number'
        >二维码</InputItem>
        <Button type='primary' style={{margin: '10px'}} onClick={()=>serachData()}>查询</Button>
        <div style={{ height: '1px', width: '100%', background: '#ddd' }}></div>
        <div style={{ background: '#108ee9', color: '#fff', width: '100%', height: '40px', lineHeight: '40px', paddingLeft: '10px', marginTop: '20px' }}>检验项目</div>
        <List className='myCheckArchive'>
          <InputItem
            value={values.surface}
            onChange={value => setValues({ ...values, surface: value })}
          >外观检查:</InputItem>
          <InputItem
            value={values.baseSpacing}
            onChange={value => setValues({ ...values, baseSpacing: value })}
            extra="(mm)"
          >底座间距检查:</InputItem>
          <InputItem
            value={values.airtightness}
            onChange={value => setValues({ ...values, airtightness: value })}
            extra="(MPa)"
          >气密性试验:</InputItem>
          <InputItem
            value={values.inspectionNumber}
            onChange={value => setValues({ ...values, inspectionNumber: value })}
          >检验编号:</InputItem>
          <InputItem
            value={values.bottleValve}
            onChange={value => setValues({ ...values, bottleValve: value })}
          >瓶阀检查:</InputItem>
          <InputItem
            value={values.waterGage}
            onChange={value => setValues({ ...values, waterGage: value })}
            extra="(MPa)"
          >水试验压力:</InputItem>
          <InputItem
            value={values.wallThickness}
            onChange={value => setValues({ ...values, wallThickness: value })}
            extra="(mm)"
          >实测最小壁厚:</InputItem>
          <Picker data={[{ value: 1, label: '合格' }, { value: 0, label: '不合格' }]} cols={1} value={[Number(values.onTest)]} onChange={value => setValues({ ...values, onTest: value[0] })}>
            <List.Item wrap arrow="horizontal">检验结论:</List.Item>
          </Picker>
          <InputItem
            value={values.valveSeat}
            onChange={value => setValues({ ...values, valveSeat: value })}
          >阀座检查:</InputItem>
          <InputItem
            value={values.volume}
            onChange={value => setValues({ ...values, volume: value })}
            extra="(L)"
          >实测容积:</InputItem>
          <DatePicker
            mode="date"
            value={moment(values.inspectionDate).toDate()}
            onChange={date => setValues({ ...values, inspectionDate: moment(date).format('YYYY-MM-DD') })}
          >
            <List.Item arrow="horizontal">检查日期</List.Item>
          </DatePicker>
          <InputItem
            value={values.inspector}
            onChange={value => setValues({ ...values, inspector: value })}
          >检验员:</InputItem>
          <InputItem
            value={values.weldLine}
            onChange={value => setValues({ ...values, weldLine: value })}
          >焊缝检查:</InputItem>
          <InputItem
            value={values.deformation}
            onChange={value => setValues({ ...values, deformation: value })}
            extra="(%)"
          >残余变形率:</InputItem>
          <InputItem
            value={values.weight}
            onChange={value => setValues({ ...values, weight: value })}
            extra="(KG)"
          >实测重量:</InputItem>
          <Picker data={[{ value: 1, label: '过检' }, { value: 0, label: '报废' }]} cols={1} value={result} onChange={value => setResult(value)}>
            <List.Item wrap arrow="horizontal">结果:</List.Item>
          </Picker>
          <TextareaItem
            title="备注/报废原因"
            autoHeight
            labelNumber={5}
            value={values.remark}
            onChange={value => setValues({ ...values, remark: value })}
          />
          <Button type='primary' style={{marginTop:'20px', marginBottom: '50px'}} onClick={()=>submit()}>保存</Button>
        </List>
      </div>
    </div>
  );
}

export default CheckArchive;
