import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import MyTabBar from '../../components/MyTabBar/index'
import request from '../../utils/request'
import path from '../../utils/path'
import four from '../../images/four.png'
import one from '../../images/one.png'
import three from '../../images/three.png'
import two from '../../images/two.png'
import homemainbg from '../../images/home_mainbg.png'

const MyListView = () => {
  let history = useHistory();
  const [data, setData] = useState({})
  const [urlCode, setUrlCode] = useState('')
  useEffect(() => {
    const url = window.location.pathname
    const codeArr = url.split('/')
    if (codeArr.length > 2) {
      const code = codeArr[codeArr.length - 1]
      setUrlCode(code)
      request.post(path.getArchivesInfo, { code }).then(v => {
        if (v.code == 'success') {
          setData(v.data);
        }
      })
    }
  }, [])

  return (
    <>
      {
        urlCode && data.unitName ?
          <>
            <div style={{
              fontSize: '5vw', display: 'flex', flexDirection: 'column', lineHeight: '24px', alignItems: 'center',
              height: '60px', justifyContent: 'center', background: '#00479d', color: '#fff'
            }}>
              <div>{data?.unitName}</div>
              <div style={{ fontSize: '4vw' }}>充装许可证编号:{data?.validityCode}</div>
            </div>
            <div style={{ padding: '0 5vw' }}>
              <div style={{ textAlign: 'center', margin: '5vw 0', fontSize: '4vw', color: '#7E7E7E', fontWeight: '700' }}>
                <div style={{ marginBottom: '10px' }}>
                  <span>订气电话：</span>
                  <a href={`tel:${data.phone}`} style={{ color: '#7e7e7e', textDecoration: 'underline' }}>{data?.phone}</a>
                </div>
                <div>
                  <span>座机电话：</span>
                  <a href={`tel:${data.telephone}`} style={{ color: '#7e7e7e', textDecoration: 'underline' }}>{data?.telephone}</a>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', position: 'relative', flexWrap: 'wrap' }}>
                <div style={{ width: '30vw', height: '30vw', position: 'absolute', top: '29vw', left: '30vw' }}>
                  <a onClick={() => history.push('/CreatOrder', { data: data, urlCode })}
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', color: '#fff', fontSize: '5.5vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>创建<br />订单</a>
                  <img src={homemainbg} style={{ width: '100%', height: '100%' }} />
                </div>

                <div style={{
                  width: '42vw', height: '42vw', background: 'linear-gradient(45deg,#2e68af,#45499f)', borderRadius: '6px', display: 'flex', flexDirection: 'column',
                  justifyContent: 'center', alignItems: 'center', marginBottom: '6vw'
                }}
                  onClick={() => history.push('/BaseInfo', { data: data, urlCode })}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={one} style={{ width: '10vw', height: '10vw' }} />
                    <span style={{ fontSize: '5vw', color: '#fff', marginLeft: '3vw' }}>基本信息</span>
                  </div>
                  <p style={{ fontSize: '3.3vw', color: '#e5e5e5', marginTop: '3.5vw' }}>充装单位、出厂编号</p>
                </div>

                <div style={{
                  width: '42vw', height: '42vw', background: 'linear-gradient(45deg,#2e68af,#45499f)', borderRadius: '6px', display: 'flex', flexDirection: 'column',
                  justifyContent: 'center', alignItems: 'center', marginBottom: '6vw'
                }} onClick={() => history.push('/RefillInfo', { data: data, urlCode })}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={two} style={{ width: '10vw', height: '10vw' }} />
                    <span style={{ fontSize: '5vw', color: '#fff', marginLeft: '3vw' }}>充装信息</span>
                  </div>
                  <p style={{ fontSize: '3.3vw', color: '#e5e5e5', marginTop: '3.5vw' }}>充装气站、充装时间</p>
                </div>

                <div style={{
                  width: '42vw', height: '42vw', background: 'linear-gradient(45deg,#2e68af,#45499f)', borderRadius: '6px', display: 'flex', flexDirection: 'column',
                  justifyContent: 'center', alignItems: 'center'
                }} onClick={() => history.push('/CheckInfo', { data: data, typeName: '检查信息', urlCode })}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={three} style={{ width: '10vw', height: '10vw' }} />
                    <span style={{ fontSize: '5vw', color: '#fff', marginLeft: '3vw' }}>检查信息</span>
                  </div>
                  <p style={{ fontSize: '3.3vw', color: '#e5e5e5', marginTop: '3.5vw' }}>充前检查、充后复查</p>
                </div>

                <div style={{
                  width: '42vw', height: '42vw', background: 'linear-gradient(45deg,#2e68af,#45499f)', borderRadius: '6px', display: 'flex', flexDirection: 'column',
                  justifyContent: 'center', alignItems: 'center'
                }} onClick={() => history.push('/Examine', { data: data, urlCode })}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={four} style={{ width: '10vw', height: '10vw' }} />
                    <span style={{ fontSize: '5vw', color: '#fff', marginLeft: '3vw' }}>检验信息</span>
                  </div>
                  <p style={{ fontSize: '3.3vw', color: '#e5e5e5', marginTop: '3.5vw' }}>检验年月、检验编号</p>
                </div>

                <div style={{
                  width: '46.5%', background: '#3290f9', color: '#fff', padding: '3vw 0', display: 'block', marginTop: '5vw', borderRadius: '5px',
                  outline: 'none', textAlign: 'center', boxShadow: '2px 2px 6px #ccc'
                }} onClick={() => history.push('/CheckInfo', { data: data, typeName: '产品合格证', urlCode })}>
                  气瓶产品合格证
                </div>
                <div style={{
                  width: '46.5%', background: '#3290f9', color: '#fff', padding: '3vw 0', display: 'block', marginTop: '5vw', borderRadius: '5px',
                  outline: 'none', textAlign: 'center', boxShadow: '2px 2px 6px #ccc'
                }} onClick={() => history.push('/CheckInfo', { data: data, typeName: '保险信息', urlCode })}>
                  气瓶保险信息
                </div>
              </div>
            </div>
            <MyTabBar /></> :
          <div style={{ textAlign: 'center', marginTop: '20px', fontSize: '5vw' }}>该编号未绑定气瓶</div>
      }
    </>
  );
}

export default MyListView;
