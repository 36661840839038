import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { List, Button, InputItem, Toast } from 'antd-mobile';
import request from '../../utils/request'
import path from '../../utils/path'

const CreatOrder = ({ location }) => {
  let history = useHistory();
  const data = location.state?.data
  const urlCode = location.state?.urlCode

  const [values, setValues] = useState({ gasUserCode: '', gasUserName:'' })

  useEffect(() => {
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition((position) => {
    //     console.log('position ', position);
    //     setValues({ ...values, address: position })
    //   })
    // } else {
    //   // alert("您的浏览器不支持使用HTML 5来获取地理位置服务");
    //   console.log("您的浏览器不支持使用HTML 5来获取地理位置服务");
    //   // getpagelistdata('', '')
    // }
  }, [])

  const submit = () => {
    if(values.gasUserCode && values.gasUserName) {
      const params = {
        ...values,
        unitId: data?.unitId
      }
      request.post(path.addOrders, {...params}).then(v=>{
        if(v.code == 'success') {
          Toast.info('创建订单成功!', 2)
          setTimeout(() => {
            history.push(`/gas/${urlCode}`)
          }, 2000)
        }
      })
    } else {
      Toast.info('请填写!', 2)
    }
  }
  return (
    <>
      <div style={{
        fontSize: '5vw', display: 'flex', flexDirection: 'column', lineHeight: '24px', alignItems: 'center',
        height: '60px', justifyContent: 'center', background: '#00479d', color: '#fff'
      }}>
        <div>{data.unitName}</div>
      </div>
      <div style={{ width: '90vw', margin: '0 auto' }}>
        <div style={{ marginTop: '10px', padding: '0 10px' }}>
          <List>
            <InputItem
              value={values.gasUserCode}
              onChange={value => setValues({ ...values, gasUserCode: value })}
              placeholder='(必填)请输入'
            >用户编号</InputItem>
            <InputItem
              value={values.gasUserName}
              onChange={value => setValues({ ...values, gasUserName: value })}
              placeholder='(必填)请输入'
            >用户名</InputItem>
            {/* <InputItem
              placeholder='(必填)请输入'
              value={values.address}
              onChange={value => setValues({ ...values, address: value })}
            >用户地址</InputItem> */}
          </List>
          <Button type='primary' style={{ marginTop: '20px', background: 'linear-gradient(90deg,#3066ae,#444a9e)' }} onClick={() => submit()}>确定</Button>
        </div>
      </div>
    </>
  );
}

export default CreatOrder;
