
export default {

  getArchivesInfo: '/api/wap/getArchivesInfo.do', //气瓶和充气单位基础信息
  getFilling: '/api/wap/getFilling.do', //获取充气信息

  addBeforeFilling: '/api/beforeFilling/addBeforeFilling.do', // 充前检查
  addAfterFilling: '/api/afterfilling/addAfterFilling.do', // 充后检查

  archivesPageData: '/api/gasArchives/pageData.do', //气瓶档案列表
  delGasArchives: '/api/gasArchives/delGasArchives.do', //删除气瓶档案

  getGasArchives: '/api/testResults/getGasArchives.do', //根据二维码获取气瓶档案
  addTestResults: '/api/testResults/addTestResults.do', //添加气瓶档案

  getGAGasStation: `/api/gasArchives/getGasStation.do`, //气站单位
  getAllMake: `/api/gasArchives/getAllMake.do`, //制造单位
  getGasSpecifications: `/api/gasArchives/getGasSpecifications.do`, //气瓶规格
  addGasArchives: `/api/gasArchives/addGasArchives.do`, //新增气瓶档案
  editGasArchives: `/api/gasArchives/editGasArchives.do`, //编辑气瓶档案
  
  addOrders: `/gasManage/orders/add.do`, // 新增订单
  ordersPageData: `/gasManage/orders/pageData.do`, // 订单列表
  acceptOrders: `/gasManage/orders/acceptOrders.do`, // 接单
  sendToOrders: `/gasManage/orders/sendToOrders.do`, // 送达
  signInOrders: `/gasManage/orders/signInOrders.do`, // 签收

}
