import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Tabs, InfiniteScroll, List, DotLoading, Divider, NavBar } from 'antd-mobile-v5'
import { gasUserData } from '../../../utils/userInfo.js'
import request from '../../../utils/request'
import path from '../../../utils/path'
import Store from '../../../images/gas/store.png'

const Order = () => {
  let history = useHistory();
  const [activeIndex, setActiveIndex] = useState('0')
  const [data, setData] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [current, setCurrent] = useState(1)

  const tabItems = [
    { key: '0', title: '新订单' },
    { key: '1', title: '待配送' },
    { key: '2', title: '待签收' },
  ]
  const InfiniteScrollContent = ({ hasMore }) => {
    return (
      <>
        {hasMore ? (
          <>
            <span>Loading</span>
            <DotLoading />
          </>
        ) : (
          <span>--- 我是有底线的 ---</span>
        )}
      </>
    )
  }
  async function loadMore() {
    const res = await request.post(path.ordersPageData, { status: activeIndex, current, pageSize: 10, employeesId: activeIndex != 0 ? gasUserData?.id : undefined, supplyStationId: gasUserData?.supplyStationId })
    if (res.code == 'success') {
      setData(val => [...val, ...res.data])
      const more = res.total > current * 10
      setHasMore(more)
      if (more) {
        setCurrent(current + 1)
      }
    }
  }
  return (
    <div style={{ height: '100vh', width: '100%', background: '#fff' }}>
      <NavBar style={{ background: 'rgb(16, 142, 233)', color: '#fff' }} onBack={() => history.push('/gasDelivery')}>接单/配送</NavBar>
      <Tabs activeKey={tabItems[activeIndex].key} onChange={key => {
        setData([])
        setHasMore(true)
        setCurrent(1)
        setActiveIndex(key)
      }}>
        {tabItems.map(item => (
          <Tabs.Tab title={item.title} key={item.key} />
        ))}
      </Tabs>
      {data.length > 0 ? <List style={{ minHeight: 'calc(100vh - 39px - 45px)' }}>
        {data.map((item, index) => (
          <div key={index} onClick={() => history.push('/gasDelivery/order/info', { data: item })} style={{ fontSize: 18, padding: '0 15px' }}>
            <div style={{ display: 'flex', alignItems: 'center', padding: '5px 0' }}>
              <img src={Store} />
              <span>[{item?.number}]</span>
              <span style={{ color: 'red' }}>[{item?.statusStr}]</span>
            </div>
            <div style={{marginLeft: 5}}>
              <div style={{marginBottom: 5}}>用户: {item?.gasUserName}</div>
              <div>供应站: {item?.supplyStationName}</div>
            </div>
            {index < data.length - 1 && <Divider style={{ borderStyle: 'dashed' }} />}
          </div>
        ))}
      </List> : <Divider>暂无数据</Divider>}
      <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
        {data.length > 0 && <InfiniteScrollContent hasMore={hasMore} />}
      </InfiniteScroll>
    </div>
  );
}

export default Order;
