
import { TabBar } from 'antd-mobile';
import knowledge from '../../images/knowledge.png'
import order from '../../images/order.png'
import myOrder from '../../images/my_order.png'
import proposal from '../../images/proposal.png'
import './index.css'

const MyTabBar = () => {


  return (

    <div className='tabBarClass' style={{ position: 'absolute', width: '100%', bottom: 0 }}>
      <TabBar
        unselectedTintColor="#949494"
        tintColor="#33A3F4"
        barTintColor="white"
      >
        <TabBar.Item
          title="使用知识"
          key="knowledge"
          onPress={() => {
            
          }}
          icon={<img src={knowledge} style={{ width: '10vw', height: '10vw' }} />}
        />
        <TabBar.Item
          title="在线下单"
          key="order"
          onPress={() => {
            
          }}
          icon={<img src={order} style={{ width: '10vw', height: '10vw' }} />}
        />
        <TabBar.Item
          title="投诉建议"
          key="proposal"
          onPress={() => {
            
          }}
          icon={<img src={proposal} style={{ width: '10vw', height: '10vw' }} />}
        />
        <TabBar.Item
          title="我的订单"
          key="myOrder"
          onPress={() => {
            
          }}
          icon={<img src={myOrder} style={{ width: '10vw', height: '10vw' }} />}
        />
      </TabBar>
    </div>
  );
}

export default MyTabBar;
