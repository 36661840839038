import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import UserData from '../../utils/userInfo.js'
import AfterQrcode from '../../images/afterQrcode.png'
import Archives from '../../images/archives.png'
import CheckArchive from '../../images/checkArchive.png'
import CheckQrcode from '../../images/checkQrcode.png'

const Index = () => {
  let history = useHistory();
  useEffect(() => {
    if(!UserData?.id) {
      history.push('/Login')
    }
  }, [])

  return (
    <>
      <div style={{
        fontSize: '5vw', display: 'flex', flexDirection: 'column', lineHeight: '24px', alignItems: 'center',
        height: '60px', justifyContent: 'center', background: 'rgb(16, 142, 233)', color: '#fff'
      }}>
        <div>首页</div>
      </div>
      <div style={{width: '100%', height: 'calc(100vh - 90px)', overflow:'hidden', margin: '0 auto', background: '#fff', paddingTop: '30px'}}>
        <div style={{textAlign: 'center', marginBottom: '30px', fontSize:'16px'}}>{UserData?.unitName}</div>
        {UserData?.roleName != '检验单位' && <div style={{display: 'flex',justifyContent: 'space-around', marginBottom: '20px'}}>
          <img src={CheckQrcode} style={{width: '135px', height: '80px'}} onClick={()=>history.push('/checkQrcode')} />
          <img src={AfterQrcode} style={{width: '135px', height: '80px'}} onClick={()=>history.push('/afterQrcode')} />
        </div>}
        <div style={{display: 'flex',justifyContent: 'space-around'}}>
          <img src={Archives} style={{width: '135px', height: '80px'}} onClick={()=>history.push('/archives')} />
          {
            UserData?.roleName == '检验单位' ? <img src={CheckArchive} style={{width: '135px', height: '80px'}} onClick={()=>history.push('/checkArchive')} />
            :<div style={{width: '135px', height: '80px'}} />
          }
        </div>
      </div>
    </>
  );
}

export default Index;
