import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Button, NavBar, Icon, Modal, Toast, InputItem } from 'antd-mobile';
import request from '../../utils/request'
import path from '../../utils/path'

const alert = Modal.alert;

const Archives = ({ location }) => {
  let history = useHistory();
  const [info, setInfo] = useState([])
  const [name, setName] = useState('')
  useEffect(() => {
    getData()
  }, [])

  const getData = () => {
    request.post(path.archivesPageData, { pageSize: 1000, current: 1, code: name }).then(v => {
      if (v.code == 'success' && Array.isArray(v.data)) {
        setInfo(v.data)
      }
    })
  }

  const splitQrcode = value => {
    const arr = value.split('/')
    setName(arr[arr.length - 1])
  }

  return (
    <div style={{ background: '#fff', minHeight: '100vh' }}>
      <NavBar mode="dark" icon={<Icon type="left" />} onLeftClick={() => history.push(`/index`)} rightContent={[
        <div key={1} style={{ fontSize: '24px' }} onClick={() => history.push({ pathname: '/crudArchive', state: { type: 'add' } })}>+</div>,
      ]}>
        <div>气瓶档案</div>
      </NavBar>
      <div style={{ width: '90vw', margin: '0 auto' }}>
        <div style={{ width: '90vw', margin: '0 auto', marginTop: '15px' }}>
          <div style={{marginBottom: '20px'}}>
            <InputItem
              value={name}
              clear
              placeholder='请输入二维码'
              onChange={value => splitQrcode(value)}
              autoFocus="autofocus"
              type='number'
            >二维码</InputItem>
            <Button type='primary' style={{ margin: '10px' }} onClick={() => getData()}>查询</Button>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #ccc', paddingBottom: '5px', marginBottom: '5px' }}>
            <div>二维码编号</div>
            <div>时间</div>
            <div>操作</div>
          </div>
          <div style={{ overflow: 'auto', height: '100vh' }}>
            {info.map((item, index) => {
              return (
                <div key={index} style={{ display: 'flex', marginTop: '8px', paddingBottom: '8px', borderBottom: '1px solid #ddd' }}>
                  <div style={{ flex: 1 }}>{item.code}</div>
                  <div style={{ flex: 1, textAlign: 'right' }}>{item?.createTime.split(' ')[0]}</div>
                  <div style={{ flex: 1, textAlign: 'right' }}>
                    <a style={{ marginRight: '10px' }} onClick={() => history.push({ pathname: '/crudArchive', state: { type: 'update', data: item } })}>修改</a>
                    <a onClick={() =>
                      alert('删除', `确定删除${item.code}档案?`, [
                        { text: '取消', onPress: () => console.log('cancel') },
                        {
                          text: '删除', onPress: () => {
                            request.post(path.delGasArchives, { id: item?.id }).then(v => {
                              if (v.code == 'success') {
                                getData()
                                Toast.success('删除成功!', 1)
                              }
                            })
                          }
                        },
                      ])
                    }>删除</a>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Archives;
