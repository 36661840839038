import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Button, NavBar, Divider } from 'antd-mobile-v5'
import { gasUserData } from '../../../utils/userInfo.js'
import request from '../../../utils/request'
import path from '../../../utils/path'
import OrderImg from '../../../images/gas/order.png'
import AddressImg from '../../../images/gas/address.png'

const OrderInfo = ({ location }) => {
  let history = useHistory();
  const data = location.state?.data

  return (
    <div style={{ height: '100vh', width: '100%', background: '#fff' }}>
      <NavBar style={{ background: 'rgb(16, 142, 233)', color: '#fff' }} onBack={() => history.push('/gasDelivery/order')}>订单详情</NavBar>
      <div style={{ fontSize: 18 }}>
        <div style={{ display: 'flex', background: '#3c4061', color: '#fff', padding: '10px 15px' }}>
          <div style={{ marginRight: 10 }}><img src={OrderImg} width={22} /></div>
          <div>
            <div style={{ marginBottom: 10 }}>订单状态: {data?.statusStr}</div>
            <div>订单号: {data?.number}</div>
          </div>
        </div>

        <div style={{ display: 'flex', padding: '10px 15px' }}>
          <div style={{ marginRight: 10 }}><img src={AddressImg} width={22} /></div>
          <div>
            <div style={{ marginBottom: 10 }}>收货人姓名: {data?.gasUserName}</div>
            <div>详细地址: {data?.gasUserAddress}</div>
          </div>
        </div>
        <Divider style={{ borderStyle: 'dashed' }} />
        <div style={{ padding: '10px 15px' }}>
          <div style={{ marginBottom: 10 }}>店铺名称: {data?.supplyStationName}</div>
          <div style={{ marginBottom: 10 }}>地址: {data?.supplyStationAddress}</div>
          <div>联系电话: {data?.supplyStationTel}</div>
        </div>
        <div style={{textAlign: 'center', marginTop: 40}}>
          <Button color='primary' style={{ width: '90%' }} onClick={() => {
            let url = ''
            if (data.status == 0) {
              url = path.acceptOrders
            } else if (data.status == 1) {
              url = path.sendToOrders
            } else if (data.status == 2) {
              url = path.signInOrders
            }
            request.post(url, { id: data.id, employeesId: gasUserData?.id }).then(res => {
              if (res.code == 'success') {
                history.push('/gasDelivery/order')
              }
            })
          }}>{data.status == 0 ? '确定' : data.status == 1 ? '送达' : '签收'}</Button>
        </div>
      </div>
    </div>
  );
}

export default OrderInfo;
