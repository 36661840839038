import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { gasUserData } from '../../utils/userInfo.js'
import Truck from '../../images/gas/truck.png'
import HomeImg from '../../images/gas/home.png'
import InfoPersonal from '../../images/gas/infoPersonal.png'
import RecordImg from '../../images/gas/record.png'
import SearchImg from '../../images/gas/search.png'
import TrophyImg from '../../images/gas/trophy.png'
import Videocamera from '../../images/gas/videocamera.png'

const GasDelivery = () => {
  let history = useHistory();
  const [data, setData] = useState()
  useEffect(() => {
    if (!gasUserData?.id) {
      history.push('/gasDelivery/login')
    }
  }, [])

  const showPosition = (position) => {
    setData(position)
    console.log('position ', position)
  }
  const errorFnc = (e) => {
    console.log(e);
  }
  return (
    <>
      <div>{data}</div>
      <div style={{
        fontSize: '5vw', display: 'flex', flexDirection: 'column', lineHeight: '24px', alignItems: 'center',
        height: '60px', justifyContent: 'center', background: 'rgb(16, 142, 233)', color: '#fff'
      }}>
        <div>送气到家</div>
      </div>
      <div style={{ background: '#fff', width: '100%', padding: '30px 0' }}>
        <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', fontSize: 18 }}>
          <div style={{ width: '50%', textAlign: 'center', marginBottom: 30 }} onClick={() => history.push('/gasDelivery/order')}>
            <img src={Truck} style={{ width: 40 }} />
            <div>接单/派送</div>
          </div>
          <div style={{ width: '50%', textAlign: 'center', marginBottom: 30 }}>
            <img src={SearchImg} style={{ width: 40 }} />
            <div>配送查询</div>
          </div>
          <div style={{ width: '50%', textAlign: 'center', marginBottom: 30 }}>
            <img src={Videocamera} style={{ width: 40 }} />
            <div>入户安检</div>
          </div>
          <div style={{ width: '50%', textAlign: 'center', marginBottom: 30 }}>
            <img src={InfoPersonal} style={{ width: 42 }} />
            <div>送气工信息</div>
          </div>
          <div style={{ width: '50%', textAlign: 'center', marginBottom: 30 }}>
            <img src={HomeImg} style={{ width: 40 }} />
            <div>客户现场配送</div>
          </div>
          <div style={{ width: '50%', textAlign: 'center', marginBottom: 30 }}>
            <img src={TrophyImg} style={{ width: 38 }} />
            <div>押瓶</div>
          </div>
          <div style={{ width: '50%', textAlign: 'center' }}>
            <img src={RecordImg} style={{ width: 36 }} />
            <div>气瓶档案</div>
          </div>
        </div>

      </div>
    </>
  );
}

export default GasDelivery;
