const userData = {
  id: '',
  roleName: '',
  unitName: '',
}

export const gasUserData = {
  id: '',
  supplyStationId: '',
}
export default userData