import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Button, NavBar, Icon, InputItem, List, Picker, Toast } from 'antd-mobile';
import request from '../../utils/request'
import path from '../../utils/path'
import './index.css'

const CheckQrcode = ({ location }) => {
  let history = useHistory();
  const [surface, setSurface] = useState(['良好'])
  const [cylinder, setCylinder] = useState(['良好'])
  const [sitting, setSitting] = useState(['良好'])
  const [weld, setWeld] = useState(['良好'])
  const [dyestuff, setDyestuff] = useState(['天蓝'])
  const [sample, setSample] = useState(['是'])
  const [code, setCode] = useState('')
  useEffect(() => {
    
  }, [])
  const checkSubmit = () => {
    if(!code) {
      Toast.info('请输入二维码!', 1)
      return
    }
    const params = {
      code, surface:surface[0], cylinder:cylinder[0],
      sitting:sitting[0], weld:weld[0], dyestuff:dyestuff[0], sample:sample[0],
      useValidity: '', auditValidity:'', rule:''
    }
    request.post(path.addBeforeFilling, params).then(v => {
      if (v.code == 'success') {
        Toast.success('保存成功！', 1)
        history.push('/index')
      }
    })
  }

  const splitQrcode = value => {
    const arr = value.split('/')
    setCode(arr[arr.length - 1])
  }
  return (
    <div style={{ background: '#fff', minHeight: '100vh' }}>
      <NavBar mode="dark" icon={<Icon type="left" />} onLeftClick={() => history.push(`/index`)}>
        <div>充装前检查</div>
      </NavBar>
      <div style={{paddingBottom: '20px'}}>
        <div style={{background: '#108ee9', marginTop: '-1px', height: '100px', position: 'relative', padding: '0 20px'}}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', background: '#fff', borderRadius: '8px', position: 'absolute', 
                marginTop:'30px', width:'calc(100% - 40px)', paddingBottom: '30px', boxShadow: '0px 0px 10px 0px rgb(192 215 246)'  }}>
            <span style={{marginTop: '30px', marginBottom: '10px'}}>二维码扫码</span>
            <InputItem style={{ border: '1px solid #ccc', height: '30px', borderRadius: '4px' }} clear type='number' autoFocus="autofocus" placeholder='光标处...' onChange={value=>splitQrcode(value)} value={code} />
          </div>
        </div>
        <List className='checkList' style={{marginTop: '90px'}}>
          <Picker data={[{value: '良好', label: '良好'},{value: '一般', label: '一般'},{value: '差', label: '差'}]} cols={1} value={surface} onChange={val=>setSurface(val)}>
            <List.Item wrap arrow="horizontal">外观检查 <span style={{fontSize: '13px', color: '#ccc'}}>选填(结果以填入的优先)</span></List.Item>
          </Picker>
          <Picker data={[{value: '良好', label: '良好'},{value: '一般', label: '一般'},{value: '差', label: '差'}]} cols={1} value={cylinder} onChange={val=>setCylinder(val)}>
            <List.Item wrap arrow="horizontal">瓶阀检查 <span style={{fontSize: '13px', color: '#ccc'}}>选填(结果以填入的优先)</span></List.Item>
          </Picker>
          <Picker data={[{value: '良好', label: '良好'},{value: '一般', label: '一般'},{value: '差', label: '差'}]} cols={1} value={sitting} onChange={val=>setSitting(val)}>
            <List.Item wrap arrow="horizontal">阀座检查 <span style={{fontSize: '13px', color: '#ccc'}}>选填(结果以填入的优先)</span></List.Item>
          </Picker>
          <Picker data={[{value: '良好', label: '良好'},{value: '一般', label: '一般'},{value: '差', label: '差'}]} cols={1} value={weld} onChange={val=>setWeld(val)}>
            <List.Item wrap arrow="horizontal">焊接检查 <span style={{fontSize: '13px', color: '#ccc'}}>选填(结果以填入的优先)</span></List.Item>
          </Picker>
          <Picker data={[{value: '天蓝', label: '天蓝'}]} cols={1} value={dyestuff} onChange={val=>setDyestuff(val)}>
            <List.Item wrap arrow="horizontal">颜色标志 <span style={{fontSize: '13px', color: '#ccc'}}>选填(结果以填入的优先)</span></List.Item>
          </Picker>
          <Picker data={[{value: '是', label: '是'},{value: '否', label: '否'}]} cols={1} value={sample} onChange={val=>setSample(val)}>
            <List.Item wrap arrow="horizontal">是否抽残 <span style={{fontSize: '13px', color: '#ccc'}}>选填(结果以填入的优先)</span></List.Item>
          </Picker>
        </List>
        <div style={{display:'flex', justifyContent: 'center', marginBottom: '20px'}}>
          <Button type='primary' style={{marginTop: '30px', width: '90%'}} onClick={()=>checkSubmit()}>检查</Button>
        </div>
      </div>
    </div>
  );
}

export default CheckQrcode;
