import { useHistory } from "react-router-dom";
import { Button } from 'antd-mobile';
import MyTabBar from '../../components/MyTabBar/index'
import '../baseInfo/baseInfo.css'

const CheckInfo = ({ location }) => {
  let history = useHistory();
  const data = location.state?.data
  const urlCode = location.state?.urlCode
  const typeName = location.state?.typeName

  return (
    <>
      <div style={{
        fontSize: '5vw', display: 'flex', flexDirection: 'column', lineHeight: '24px', alignItems: 'center',
        height: '60px', justifyContent: 'center', background: '#00479d', color: '#fff'
      }}>
        <div>{data.unitName}</div>
      </div>
      <div style={{width: '90vw', margin: '0 auto'}}>
        <p style={{fontSize:'5vw',lineHeight:'16vw',textAlign:'center',marginTop:'3vw'}}>该气瓶暂无{typeName}!</p>
        <Button type="primary" style={{ marginTop: '10px', background:'linear-gradient(90deg,#3066ae,#444a9e)' }} onClick={() => history.push(`/gas/${urlCode}`)}>返回</Button>
      </div>
      <MyTabBar />
    </>
  );
}

export default CheckInfo;
